import { useMutation, useQuery } from '@apollo/client';
import { IPatient, JobStatus, Patient } from '@doc-abode/data-models';
import { Formik, FormikValues } from 'formik';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import { FC, useContext, useEffect, useState } from 'react';

import { GET_JOB_BY_ID, UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import { jobCreator } from '../../../../../helpers/jobCreator/jobCreator';
import { JobType } from '../../../../../helpers/jobCreator/schemas/JobTypes';
import useStores from '../../../../../hook/useStores';
import { JobsContext } from '../../../../../providers';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import { useView } from '../../views/useView';
import ReviewForm from '../AddVisit/ReviewForm';
import validationSchema from '../AddVisit/validation';
import { FormMode, FormSteps } from '../common';
import EditVisitForm from './EditVisitForm';

// todo type VisitData also exists in AddVisitTypes
export type VisitData = IPatient & {
    createDateTime: string;
    startDateTime: string;
    earliestDateOfVisit?: string;
    duration: string;
    availableFrom?: Moment;
    availableTo?: Moment;
    arrivedDateTime?: string;
    finishedDateTime?: string;
    dateOfBirth: string;
    jobType: string;
    visitDate: Moment;
    startTime: Moment;
    hcpId?: string;
    buddyId?: string;
    staffRequired?: string;
};
// todo type APIVisitData also exists in AddVisitTypes
export type APIVisitData = IPatient & {
    createDateTime: Date;
    startDateTime: Date;
    earliestDateOfVisit?: Date;
    duration: string;
    dateOfBirth: Date;
    jobType: string;
    hcpId?: string;
    hcpName?: string;
    buddyName?: string;
    buddyId?: string;
    staffRequired?: string;
    visitDate?: Moment;
    startTime?: Moment;
    endTime?: Moment;
    arrivedDateTime?: string;
    finishedDateTime?: string;
    buddyArrivedDateTime?: string;
    buddyFinishedDateTime?: string;
    latitude?: number;
    longitude?: number;
};

const getNewFormValues = (values: FormikValues): VisitData => {
    const newValues = {
        ...(cloneDeep(values) as VisitData),
        contactNumber: values.contactNumber,
    };

    return newValues;
};

const EditVisit: FC = () => {
    const {
        RootStore: {
            configStore: { org, adminTypes, pathways },
            userStore: {
                user: { username },
            },
            usersStore: { users },
            ucrStore: { setOpenedDialogAlert, focusedJobId, jobs, selectedDate },
        },
    } = useStores<{ RootStore: RootStore }>();

    const jobsContext = useContext(JobsContext);
    const { currentViewState } = useView();

    const getVisit = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
        pollInterval: 60000,
    });

    const [formData, setFormData] = useState<{
        step: FormSteps;
        values?: VisitData | null;
        initialValues?: FormikValues | null;
        formMode: FormMode;
    }>({
        step: FormSteps.REVIEW,
        formMode: FormMode.EDIT_VISIT,
    });

    const [updateJob, { loading, error }] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            getVisit?.refetch?.();
            if (!currentViewState.patientList) {
                const isUnassigned =
                    (formData.values?.staffRequired !== 2 && !formData.values?.hcpId) ||
                    (formData.values?.staffRequired === 2 &&
                        !formData.values?.hcpId &&
                        !formData.values?.buddyId);

                if (
                    isUnassigned ||
                    moment(formData.values?.visitDate).isSame(moment(selectedDate), 'day')
                ) {
                    jobsContext.setRefreshAssignedJobs(true);
                }
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });

    const [initialised, setInitialised] = useState(false);

    const onSubmit = async (values: FormikValues) => {
        const newValues = getNewFormValues(values);

        setFormData((data) => ({
            ...data,
            values: newValues,
        }));
    };

    const onSave = async (values: FormikValues) => {
        const newValues = getNewFormValues(values);

        setFormData((data) => ({
            ...data,
            values: newValues,
            initialValues: newValues,
        }));

        const isJobArrived = values.jobStatus === JobStatus.ARRIVED;
        const isJobCurrent = values.jobStatus === JobStatus.CURRENT;

        const isBuddyJobArrived = values.buddyJobStatus === JobStatus.ARRIVED;
        const isBuddyJobCurrent = values.buddyJobStatus === JobStatus.CURRENT;
        const currentJob = jobs.find((job: Patient) => {
            if (job.id === focusedJobId) {
                return job;
            }
            return false;
        });

        const hcpWasChanged = (isJobArrived || isJobCurrent) && currentJob?.hcpId !== values.hcpId;
        const buddyWasChanged =
            (isBuddyJobArrived || isBuddyJobCurrent) && currentJob?.hcpId !== values.buddyId;

        const formattedData = jobCreator({
            values: {
                ...(newValues as unknown as any),
                ...{
                    _additionalValues: {
                        org,
                        username,
                        id: focusedJobId,
                        users,
                        staffMemberWasChanged: hcpWasChanged,
                        staffMemberWasChangedBuddy: buddyWasChanged,
                    },
                },
            },
            onCreate: false,
            typeOfJob: JobType.VISIT,
            pathways,
            adminTypes,
        });

        try {
            await updateJob({ variables: { input: formattedData } });
        } catch (err) {
            console.log('Error updating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unabled to update the visit',
                intent: 'danger',
            });
            return;
        }

        AppToaster.show({
            message: 'Visit updated successfully!',
            intent: 'success',
        });
    };

    useEffect(() => {
        const job = getVisit.data?.getJob;

        const newValues = {
            ...job,
            contactNumber: job.contactNumber || '',
            additionalContactNumbers:
                job.additionalContactNumbers?.filter((str: string) => str) || [],
            middleName: job.middleName || '',
            addressLine2: job.addressLine2 || '',
            addressLine3: job.addressLine3 || '',
            dateOfBirth: new Date(job.dateOfBirth),
            referralDateTime: job.referralDateTime ? new Date(job.referralDateTime) : undefined,
            visitDate: new Date(job.startDateTime || job.dateOfVisit),
            availableFrom: job.availableFrom ? new Date(job.availableFrom) : undefined,
            availableTo: job.availableTo ? new Date(job.availableTo) : undefined,
            arrivedDateTime: job.arrivedDateTime ? new Date(job.arrivedDateTime) : undefined,
            finishedDateTime: job.finishedDateTime ? new Date(job.finishedDateTime) : undefined,
            buddyArrivedDateTime: job.buddyArrivedDateTime
                ? new Date(job.buddyArrivedDateTime)
                : undefined,
            buddyFinishedDateTime: job.buddyFinishedDateTime
                ? new Date(job.buddyFinishedDateTime)
                : undefined,
            startTime: job.startDateTime ? new Date(job.startDateTime) : undefined,
            earliestDateOfVisit: job.earliestDateOfVisit ? new Date(job.earliestDateOfVisit) : null,
            staffPreferredGender: job.staffPreferredGender || [],
            languagesSpoken: job.languagesSpoken || [],
            buddyJobStatus: job.buddyJobStatus,
        };

        delete newValues.__typename;

        if (!initialised) {
            setFormData((data) => ({
                ...data,
                values: newValues,
                initialValues: newValues,
            }));
            setInitialised(true);
        }
    }, [getVisit, initialised]);

    const onEditReviewForm = (step: FormSteps) => {
        setOpenedDialogAlert(DialogAlerts.SAVE_VISIT);

        setFormData((data) => {
            return {
                ...data,
                step,
                values: null,
            };
        });
    };

    useEffect(() => {
        setOpenedDialogAlert(!formData.values ? DialogAlerts.SAVE_VISIT : DialogAlerts.NONE);
    }, [formData.values, setOpenedDialogAlert]);

    return (
        <Formik
            initialValues={{
                ...formData.initialValues,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {formData.values ? (
                <ReviewForm
                    values={formData.values}
                    patient={getVisit.data?.getJob}
                    formMode={formData.formMode}
                    onEdit={onEditReviewForm}
                    isJobCreated={true}
                />
            ) : (
                initialised && (
                    <EditVisitForm
                        step={formData.step}
                        loading={loading}
                        error={error}
                        formMode={formData.formMode}
                        onSave={onSave}
                    />
                )
            )}
        </Formik>
    );
};

export default EditVisit;
