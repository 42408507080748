import { Formik } from 'formik';
import { FunctionComponent } from 'react';

import { FormMode } from '../common';
import AdminTimeForm from './AdminTimeForm';
import { TAdminTimeData } from './AdminTimeTypes';
import ReviewForm from './ReviewForm';
import { useAdminTimeViewModel } from './useAdminTimeViewModel';

type AdminTimeType = {
    isEdit: boolean;
};

const AdminTime: FunctionComponent<AdminTimeType> = ({ isEdit = false }) => {
    const {
        loading,
        initialised,
        initialData,
        visitData,
        params,
        patientFieldsRequired,
        updateJobData,
        validationSchema,
        handleChangePatientRequired,
        setVisitData,
        onSubmit,
        saveVisitData,
        updateVisitData,
        onEditReviewForm,
    } = useAdminTimeViewModel({ isEdit });

    return (
        <Formik
            initialValues={initialData as TAdminTimeData}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {visitData ? (
                <ReviewForm
                    values={visitData}
                    onSave={isEdit ? updateVisitData : saveVisitData}
                    onEdit={onEditReviewForm}
                    formMode={isEdit ? FormMode.EDIT_VISIT : params.formMode}
                    setVisitData={setVisitData}
                />
            ) : (
                (initialised || !isEdit) && (
                    <AdminTimeForm
                        handleSubmit={isEdit ? updateVisitData : onSubmit}
                        step={params.step}
                        loading={isEdit ? updateJobData.loading : loading}
                        formMode={params.formMode}
                        patientFieldsRequired={patientFieldsRequired}
                        handleChangePatientRequired={handleChangePatientRequired}
                    />
                )
            )}
        </Formik>
    );
};

export default AdminTime;
