import { useMutation } from '@apollo/client';
import { JobStatus, Patient } from '@doc-abode/data-models';
import { DEFAULT_ADMIN_DURATION, getDefaultAdminDuration } from '@doc-abode/helpers';
import { FormikValues } from 'formik';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';

import { CREATE_JOB, UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import {
    getStartDateForDefaultVisitAndAdmin,
    getStartDateForFollowUp,
} from '../../../../../helpers/getStartDateForFollowUp';
import { jobCreator } from '../../../../../helpers/jobCreator/jobCreator';
import { JobType } from '../../../../../helpers/jobCreator/schemas/JobTypes';
import useStores from '../../../../../hook/useStores';
import { JobsContext } from '../../../../../providers';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import { useApolloActions } from '../../actions/useApolloActions';
import { useView } from '../../views/useView';
import { FormMode, FormSteps } from '../common';
import { TAdminTimeData } from './AdminTimeTypes';
import { getValidationSchema } from './validation';

type AdminTimeType = {
    isEdit: boolean;
};

export const useAdminTimeViewModel = ({ isEdit }: AdminTimeType) => {
    const {
        RootStore: {
            configStore: { org, adminTypes, pathways },
            ucrStore: {
                setOpenedDialog,
                setOpenedDialogAlert,
                followUpAdminTimeData,
                setFollowUpAdminTimeData,
                focusedJobId,
                selectedDate,
            },
            userStore: {
                user: { username },
            },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    const jobsContext = useContext(JobsContext);

    const { currentViewState } = useView();

    const [createJob, { loading }] = useMutation(CREATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                if (moment(formData.visitDate).isSame(moment(selectedDate), 'day')) {
                    jobsContext.setRefreshAssignedJobs(true);
                }
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });
    const [updateJob, updateJobData] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                if (moment(formData.visitDate).isSame(moment(selectedDate), 'day')) {
                    jobsContext.setRefreshAssignedJobs(true);
                }
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });
    const [visitData, setVisitData] = useState<FormikValues | null>(null);
    const [params, setParams] = useState<{
        step: FormSteps;
        formMode: FormMode;
    }>({
        step: isEdit ? FormSteps.REVIEW : FormSteps.PATIENT,
        formMode: isEdit ? FormMode.EDIT_VISIT : FormMode.DEFAULT,
    });

    const [formData, setFormData] = useState<FormikValues>({
        gender: '',
        hcpId: '',
        activityType: '',
        visitDate: getStartDateForDefaultVisitAndAdmin({
            systemTime: selectedDate,
            fromPatientListView: currentViewState.patientList,
        }),
        startTime: new Date(),
        duration: DEFAULT_ADMIN_DURATION,
        createdBy: '',
        lastUpdatedBy: '',
        lastUpdatedDateTime: '',
        createDateTime: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        postCode: '',
        languagesSpoken: [],
        jobStatus: JobStatus.ACCEPTED,
    });

    const [initialised, setInitialised] = useState(false);

    const [patientFieldsRequired, setPatientFieldsRequired] = useState(false);
    const [validationSchema, setValidationSchema] = useState(getValidationSchema({}));
    const [focusedJobData, setFocusedJobData] = useState<Patient | null>(null);
    const [focusedJobDataRequested, setFocusedJobDataRequested] = useState(false);

    const { actionsGetJob } = useApolloActions({});

    const loadFocusedJobData = useCallback(async () => {
        await actionsGetJob.getJobByIdOperation({
            variables: {
                id: focusedJobId,
            },
            onCompleted: (data) => {
                setFocusedJobData(data.getJob);
            },
        });
    }, [actionsGetJob, focusedJobId]);

    // If the admin time form is related to a focused job, and we've not fetched the data for it
    // yet, do that now
    useEffect(() => {
        if (!focusedJobDataRequested && focusedJobId) {
            setFocusedJobDataRequested(true);
            loadFocusedJobData();
        }
    }, [loadFocusedJobData, focusedJobDataRequested, focusedJobId]);

    useEffect(() => {
        if (!focusedJobData || !isEdit) return;

        const adminTypeFields = [
            'nhsNumber',
            'firstName',
            'middleName',
            'lastName',
            'contactNumber',
            'additionalContactNumbers',
            'dateOfBirth',
            'gender',
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'town',
            'postCode',
            'latitude',
            'longitude',
            'systmOneRef',
            'languagesSpoken',
            'staffPreferredGender',
            'activityType',
            'notes',
            'postVisitNotes',
            'id',
        ];

        let filledData = {};
        Object.keys(focusedJobData).forEach((field: string) => {
            const typedField = field as keyof Patient;
            if (adminTypeFields.includes(field) && focusedJobData[typedField] !== null) {
                filledData = { ...filledData, [field]: focusedJobData[typedField] };
            }
        });

        const formattedVisitData = {
            ...filledData,
            hcpId: focusedJobData.hcpId,
            visitDate: new Date(focusedJobData.startDateTime || selectedDate),
            startTime: new Date(focusedJobData.startDateTime || selectedDate),
            dateOfBirth: focusedJobData.dateOfBirth
                ? moment(focusedJobData.dateOfBirth).toDate()
                : undefined,
            duration: focusedJobData.duration,
            jobStatus: focusedJobData.jobStatus,
            ...(focusedJobData.pds?.versionId && {
                pds: { versionId: focusedJobData.pds.versionId },
            }),
        };

        if (!initialised) {
            setFormData(formattedVisitData as TAdminTimeData);
            setVisitData(formattedVisitData as TAdminTimeData);
            setInitialised(true);
            handleChangePatientRequired(!!(formattedVisitData as TAdminTimeData).nhsNumber);
        }
    }, [initialised, focusedJobData, isEdit, username, selectedDate]);

    let initialData = formData;

    /** Get default fields for follow up, some reason duration + startTime do not exist on followUpAdminTimeData */
    if (followUpAdminTimeData) {
        const followUpDataFields = jobCreator({
            values: {
                startTime: new Date(),
                ...(followUpAdminTimeData as unknown as any),
                ...{
                    _additionalValues: {
                        org,
                        username,
                        id: undefined,
                        users,
                    },
                },
            },
            followUp: true,
            onCreate: true,
            typeOfJob: JobType.ADMIN,
            pathways,
            adminTypes,
        });

        initialData = { ...formData, ...followUpDataFields };
        initialData.visitDate = getStartDateForFollowUp({
            systemTime: selectedDate,
            dateOfVisit: moment(followUpAdminTimeData.dateOfVisit).toDate(),
            fromPatientListView: currentViewState.patientList,
        });

        initialData.duration = getDefaultAdminDuration({
            adminTypes,
            selectedAdminType: initialData.activityType,
        });
    }

    const onSubmit = async (values: FormikValues) => {
        const data = {
            ...cloneDeep(values),
            contactNumber: values.contactNumber ? values.contactNumber : undefined,
            additionalContactNumbers: values.additionalContactNumbers
                ? values.additionalContactNumbers.filter((str: string) => str)
                : undefined,
        };

        setVisitData(data as TAdminTimeData);
        setFormData(values as TAdminTimeData);
    };

    const successSaving = () => {
        AppToaster.show({
            message: `Visit ${isEdit ? 'updated' : 'saved'} successfully!`,
            intent: 'success',
        });

        setFollowUpAdminTimeData(null);
        setOpenedDialog(Dialogs.NONE);
    };

    const saveVisitData = async (visitData: FormikValues) => {
        if (!visitData) return;

        const formattedData = jobCreator({
            values: {
                ...(visitData as unknown as any),
                ...{
                    _additionalValues: {
                        org,
                        username,
                        id: undefined,
                        users,
                    },
                },
            },
            onCreate: true,
            typeOfJob: JobType.ADMIN,
            pathways,
            adminTypes,
        });

        try {
            await createJob({ variables: { input: formattedData } });
        } catch (err) {
            console.error('Error creating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unabled to create the visit',
                intent: 'danger',
            });
            return;
        }
        successSaving();
    };

    const updateVisitData = async (visitData: FormikValues) => {
        if (!visitData) return;
        const hcpWasChanged = initialData.hcpId !== visitData.hcpId;
        const formattedData = jobCreator({
            values: {
                ...(visitData as unknown as any),
                ...{
                    _additionalValues: {
                        org,
                        username,
                        id: focusedJobData?.id,
                        users,
                        staffMemberWasChanged: hcpWasChanged,
                    },
                },
            },
            onCreate: false,
            typeOfJob: JobType.ADMIN,
            pathways,
            adminTypes,
        });

        if (formattedData) {
            formattedData.lastUpdatedDateTime = moment().toISOString();
        }

        try {
            await updateJob({ variables: { input: formattedData } });
        } catch (err) {
            console.error('Error updating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to create the visit',
                intent: 'danger',
            });
            return;
        }

        successSaving();
    };

    const onEditReviewForm = (step: FormSteps) => {
        setOpenedDialogAlert(DialogAlerts.SAVE_ADMINISTRATIVE_TIME);
        setParams({
            step,
            formMode: isEdit ? FormMode.EDIT_VISIT : params.formMode,
        });
        setVisitData(null);
    };

    useEffect(() => {
        setOpenedDialogAlert(
            !visitData ? DialogAlerts.SAVE_ADMINISTRATIVE_TIME : DialogAlerts.NONE,
        );
    }, [visitData, setOpenedDialogAlert]);

    useEffect(() => {
        setValidationSchema(getValidationSchema({ patientFieldsRequired }));
    }, [patientFieldsRequired]);

    const handleChangePatientRequired = (isRequired: boolean) =>
        setPatientFieldsRequired(isRequired);

    return {
        loading,
        initialised,
        patientFieldsRequired,
        initialData,
        visitData,
        params,
        updateJobData,
        validationSchema,
        handleChangePatientRequired,
        onSubmit,
        setVisitData,
        saveVisitData,
        updateVisitData,
        onEditReviewForm,
    };
};
